import React from 'react'
import './style.css'
import '../../assets/animations/style.css'
import ImgLoading from '../../assets/img/loading.png'

function Loading() {
    return (
        <div className="loader-container">
            <img className="loader-img__spin" src={ImgLoading} alt="" />
        </div>
    )
}

export default Loading
