import { Suspense, lazy } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Loading from "./components/Loading/Loading.jsx"

// import Header from './components/Header/Header.jsx';
// import Home from './pages/Home.jsx';
// import Leaderboard from "./pages/Leaderboard.jsx";
// import Error from './pages/404.jsx'

const Header  = lazy(() => import('./components/Header/Header.jsx'))
const Home  = lazy(() => import('./pages/Home.jsx'))
const Leaderboard  = lazy(() => import('./pages/Leaderboard.jsx'))
const Error  = lazy(() => import('./pages/404.jsx'))
const Register = lazy(() => import('./pages/Register.jsx'))

function App() {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
          <Header />
        <main>
          <Routes exact path="/" element={<Header />} >
            <Route exact path="/" element={<Home />} />
            <Route exact path="/leaderboard" element={<Leaderboard />} />
            <Route exact path="/how-to-register" element={<Register />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </main>
      </BrowserRouter>
    </Suspense>
  );
}


export default App;
